/*eslint-disable*/
import React from "react";
import { Link } from "react-router-dom";


import IndexNavbar from "components/Navbars/IndexNavbar.js";
import Footer from "components/Footers/Footer.js";

export default function Index() {
  return (
    <>
      <IndexNavbar fixed />
      <section className="header relative pt-16 items-center flex h-screen max-h-860-px inicio  ">
      <span className="w-full h-full absolute"></span>
        <div className="container mx-auto items-center flex flex-wrap ">
          <h2 className="font-semibold text-4xl text-blueGray-600 painel">
            O agro está presente em todos os processos de evolução do mundo e hoje mantém o Brasil como um dos maiores países
          </h2>
        </div>
      </section>
      <section className="mt-48 md:mt-40 pb-40 relative bg-blueGray-100">
        <div className="-mt-20 top-0 bottom-auto left-0 right-0 w-full absolute h-20" style={{ transform: "translateZ(0)" }}>
          <svg className="absolute bottom-0 overflow-hidden" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="none" version="1.1"viewBox="0 0 2560 100"
            x="0" y="0" >
            <polygon className="text-blueGray-100 fill-current" points="2560 0 2560 100 0 100" ></polygon>
          </svg>
        </div>
        <div className="container mx-auto">
          <div className="flex flex-wrap items-center">
            <div className="w-10/12 md:w-6/12 lg:w-4/12 px-12 md:px-41 mr-auto ml-auto -mt-32">
              <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded-lg bg-lightBlue-500">
                <img  alt="..."  src="https://img.freepik.com/fotos-gratis/agricultura-inteligente-iot-com-fundo-de-arvore-de-plantio-a-mao_53876-124626.jpg?t=st=1649857715~exp=1649858315~hmac=c5dccf33f077444bd488b949b04d1d8f2ba7c8e055e4820d8656351589b299f7&w=1380"
                  className="w-full align-middle rounded-t-lg"
                />
                <blockquote className="relative p-8 mb-4">
                  <svg preserveAspectRatio="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 583 95" className="absolute left-0 w-full block h-95-px -top-94-px" >
                    <polygon points="-30,95 583,95 583,65" className="text-lightBlue-500 fill-current" ></polygon>
                  </svg>
                  <h4 className="text-2x  mb-1 font-bold text-white"> 
                    Diversas ferramentas para uma gestão mais eficiente.
                  </h4>
                  <p className="text-md font-light mt-2 text-white"> </p> 
                </blockquote>
              </div>
            </div>
            <div className="w-full md:w-6/12 px-41">
              <div className="flex flex-wrap">
                <div className="w-full md:w-6/12 px-41 ">
                  <div className="relative flex flex-col mt-4">
                    <div className="px-4 py-5 flex-auto">
                      <div className="text-blueGray-500 p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-white">
                      <i className="fas fa-file-alt"></i>
                      </div>
                      <h4 className="text-xl mb-1 font-semibold text-blueGray-500">
                        Você Produtor Rural deseja emitir nota fiscal?
                      </h4>
                      <h5 className="mb-4 text-blueGray-500">
                        Veja nossa plataforma de emissões fiscais Emtitir DFE.
                      </h5>
                      <a href="https://emitirdfe.com.br/" target="_blank" className="font-bold text-blueGray-700 hover:text-blueGray-500 ease-linear transition-all duration-150" >
                        Ver mais{" "}
                        <i className="fa fa-angle-double-right ml-1 leading-relaxed"></i>
                      </a>
                    </div>
                  </div>
                  <div className="relative flex flex-col min-w-0">
                    <div className="px-4 py-5 flex-auto">
                      <div className="text-blueGray-500 p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-white">
                      <i className="fas fa-address-card"></i>
                      </div>
                      <h6 className="text-xl mb-1 font-semibold text-blueGray-500">Para você transportadora, temos integração com CIOT</h6>
                      <h5 className="mb-4 text-blueGray-500">
                        Gere suas cobranças de CIOT diretamente por nosso sistema.
                      </h5>
                    </div>
                  </div>
                </div>
                <div className="w-full md:w-6/12 px-4">
                  <div className="relative flex flex-col min-w-0 mt-4">
                    <div className="px-41 py-5 flex-auto">
                      <div className="text-blueGray-500 text-blueGray-500 p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-white">
                      <i className="fas fa-calculator"></i>
                      </div>
                      <h6 className="text-xl mb-1 font-semibold text-blueGray-500">
                        Temos parceria com o Portal Onvio
                      </h6>
                      <h5 className="mb-4 text-blueGray-500">
                        Suas notas estarão em sincronia com sua contabilidade.
                      </h5>
                    </div>
                  </div>
                  <div className="relative flex flex-col min-w-0">
                    <div className="px-41 py-5 flex-auto">
                      <div className="text-blueGray-500 p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-white">
                      <i className="fas fa-thumbs-up"></i>
                      </div>
                      <h6 className="text-xl mb-1 font-semibold text-blueGray-500">
                        Buscando sempre facilitar seu dia a dia
                      </h6>         
                      <h5 className="mb-4 text-blueGray-500">
                        Sistemas práticos, fáceis e que vão otimizar sua vida.
                      </h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container mx-auto overflow-hidden pb-20">
          <div className="flex flex-wrap items-center ">
          <div className="w-full md:w-4/12 px-12 md:px-4 ml-auto mr-auto mt-48">
              <div className="text-blueGray-501 p-3 text-center inline-flex items-center justify-center w-16 h-16 mb-6 shadow-lg rounded-full bg-white">
              <i className="fas fa-bars"></i>
              </div>
              <h1 className="text-3xl mb-2 font-semibold  text-blueGray-500 leading-normal">
                Ramos de Atuação
              </h1>
              <p className="mt-4 text-lg leading-relaxed text-blueGray-500">
                A solução ideal para o seu negócio, com uma gestão de informação abrangente e eficiente, 
                integrando todos os departamentos da sua empresa por módulo.
              </p>
            </div>
            <div className="w-full md:w-6/12 px-4 mr-auto ml-auto mt-32">
              <div className="justify-center flex flex-wrap relative">
                <div className="my-4 w-full lg:w-6/12 px-4">
                  <a >
                    <div className="bg-red-600 shadow-lg rounded-lg text-center p-8">
                      <img  alt="..." className="shadow-md rounded-full max-w-full w-16 mx-auto p-2 bg-white"src={require("assets/img/armazenar.png").default} />
                      <p className="text-lg text-white mt-4 font-semibold" >
                        Silos e Armazéns de Grãos
                      </p> 
                    </div>
                  </a>
                  <a>
                    <div className="bg-lightBlue-500 shadow-lg rounded-lg text-center p-8 mt-8">
                      <img alt="..." className="shadow-md rounded-full max-w-full w-16 mx-auto p-2 bg-white" src={require("assets/img/agricultor.png").default} />
                      <p className="text-lg text-white mt-4 font-semibold">
                        Fazendas e Produtores Rurais
                      </p>
                    </div>
                  </a>
                  <a >
                    <div className="bg-blueGray-700 shadow-lg rounded-lg text-center p-8 mt-8">
                      <img alt="..." className="shadow-md rounded-full max-w-full w-16 mx-auto p-2 bg-white" src={require("assets/img/natureza.png").default}/>
                      <p className="text-lg text-white mt-4 font-semibold">
                        Comercialização de Cereais
                      </p>
                    </div>
                  </a>
                  <br/>
                  <a>
                    <div className="bg-yellow-500 shadow-lg rounded-lg text-center p-8">
                      <img alt="..." className="shadow-md rounded-full max-w-full w-16 mx-auto p-2 bg-white" src={require("assets/img/troca.png").default} />
                      <p className="text-lg text-white mt-4 font-semibold">
                        Revenda de Produtos
                      </p>
                    </div>
                  </a>
                </div>
                <div className="my-4 w-full lg:w-6/12 px-4 lg:mt-16">
                  <a>
                    <div className="bg-red-700 shadow-lg rounded-lg text-center p-8 mt-8">
                      <img alt="..." className="shadow-md rounded-full max-w-full w-16 mx-auto p-2 bg-white" src={require("assets/img/industria.png").default}   />
                      <p className="text-lg text-white mt-4 font-semibold">
                        Indústrias
                      </p>
                    </div>
                  </a>
                  <a>
                    <div className="bg-emerald-500 shadow-lg rounded-lg text-center p-8 mt-8">
                      <img alt="..." className="shadow-md rounded-full max-w-full w-16 mx-auto p-2 bg-white" src={require("assets/img/caminhao.png").default} />
                      <p className="text-lg text-white mt-4 font-semibold">
                        Logística
                      </p>
                    </div>
                  </a>
                  <a>
                    <div className="bg-emerald-501 shadow-lg rounded-lg text-center p-8 mt-8">
                      <img alt="..." className="shadow-md rounded-full max-w-full w-16 mx-auto p-2 bg-white" src={require("assets/img/mais.png").default} />
                      <p className="text-lg text-white mt-4 font-semibold">
                        Demais Soluções
                      </p>
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container mx-auto px-4 pb-32">
          <div className="items-center flex flex-wrap">
            <div className="w-full md:w-5/12 ml-auto px-12 md:px-4">
              <div className="md:pr-12">
                <div className="text-blueGray-500 p-3 text-center inline-flex items-center justify-center w-16 h-16 mb-6 shadow-lg rounded-full bg-white">
                  <i className="fas fa-clone"></i>
                </div>
                <h3 className="text-3xl font-semibold text-blueGray-500">
                  Conheça alguns dos nossos produtos!
                </h3>
                <ul className="list-none mt-6">
                  <li className="py-2">
                    <div className="flex items-center">
                      <div>
                        <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-blueGray-500 bg-blueGray-50 mr-3">
                        <i className="fa fa-angle-double-right ml-1 leading-relaxed"></i>
                        </span>
                      </div>
                      <div>
                        <a href="https://silovirtual.com.br" target="_blank" className="font-bold text-blueGray-700 hover:text-blueGray-500 ease-linear transition-all duration-150" >
                          Silo Virtual{" "}
                        </a>
                      </div>
                    </div>
                    <p className="text-lg mt-0 mb-2 text-blueGray-500">
                      A plataforma Silo Virtual é uma forma de acompanhar o contrato do agricultor
                      com seu armazém de forma rápida e prática, auxiliando no controle geral da fazenda.
                    </p>
                  </li>
                  <li className="py-2">
                    <div className="flex items-center">
                      <div>
                        <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-blueGray-500 bg-blueGray-50 mr-3">
                        <i className="fa fa-angle-double-right ml-1 leading-relaxed"></i>
                        </span>
                      </div>
                      <div>
                        <a href="http://bzerro.com.br" target="_blank" className="font-bold text-blueGray-700 hover:text-blueGray-500 ease-linear transition-all duration-150" >
                          Bzerro{" "}
                        </a>
                      </div>
                    </div>
                    <p className="text-lg mt-0 mb-2 text-blueGray-500">
                      Na plataforma Bzerro é possivel controlar seu rebanho, acompanhar suas receitas e despesas, controlar seu financeiro e muito mais!
                    </p>
                  </li>
                </ul>
              </div>
            </div>
            <div className="w-full md:w-6/12 mr-auto px-4 pt-24 md:pt-0">
              <div className="pic-ctn ">
                 <img src={require("assets/img/home.png").default} alt="..." className="max-w-full rounded-lg shadow-xl"   width="36%" height="-36px"  style={{ transform: "scale(1) perspective(1040px) rotateY(-11deg) rotateX(2deg) rotate(2deg)", }}></img>
                <img src={require("assets/img/Bzerro.png").default} alt="..." className="max-w-full rounded-lg shadow-xl" width="36%" height="-36px" style={{ transform: "scale(1) perspective(1040px) rotateY(-11deg) rotateX(2deg) rotate(2deg)", }} ></img>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="pb-16 bg-blueGray-200 relative pt-32">
        <div className="-mt-20 top-0 bottom-auto left-0 right-0 w-full absolute h-20" style={{ transform: "translateZ(0)" }} >
          <svg className="absolute bottom-0 overflow-hidden"  xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="none" version="1.1"viewBox="0 0 2560 100"  x="0" y="0" >
            <polygon className="text-blueGray-200 fill-current" points="2560 0 2560 100 0 100" ></polygon>
          </svg>
        </div>
        <section className="relative block py-24 lg:pt-0">
          <div className="container mx-auto px-4">
            <div className="flex flex-wrap justify-center lg:-mt-64 -mt-48">
              <div className="w-full lg:w-6/12 px-4">
                <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-200">
                  <div className="flex-auto p-5 lg:p-10">
                  <h2 className="font-semibold text-4xl text-center text-blueGray-500">Contato</h2>
                    <p className="leading-relaxed mt-1 mb-4 text-blueGray-500 text-center">
                      Deixe uma mensagem que entraremos em contato com você.
                    </p>
                    <div className="relative w-full mb-3 ">
                      <label className="block uppercase text-blueGray-601 text-xs font-bold mb-2" htmlFor="full-name" >
                        Nome
                      </label>
                      <input type="text" className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-601 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        placeholder="Digite o seu nome"
                      />
                    </div>
                    <div className="relative w-full mb-3 ">
                      <label className="block uppercase text-blueGray-601 text-xs font-bold mb-2"  htmlFor="full-name" >
                        Empresa
                      </label>
                      <input type="text" className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-601 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        placeholder="Digite o nome da sua empresa"
                      />
                    </div>
                    <div className="relative w-full mb-3">
                      <label className="block uppercase text-blueGray-601 text-xs font-bold mb-2" htmlFor="email">
                        Email
                      </label>
                      <input type="email" className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-601 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        placeholder="Digite seu email"
                      />
                    </div>
                    <div className="relative w-full mb-3">
                      <label className="block uppercase text-blueGray-601 text-xs font-bold mb-2"   htmlFor="Mensagem" >
                        Mensagem
                      </label>
                      <textarea rows="4" cols="80" className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-601 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full"
                        placeholder="Digite sua mensagem..."
                      />
                    </div>
                    <div className="text-center mt-6">
                      <button
                        className="bg-blueGray-800 text-white active:bg-blueGray-600 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                        type="button">
                        Enviar 
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/font-awesome/4.5.0/css/font-awesome.min.css" target="_blank" ></link>
        <a 
          id="zapzap"
          href="https://wa.me/556699984060?text=Olá, estou entrando em contato pelo site Ruralhub, gostaria de informações sobre sistemas"
          style={{ position: "fixed", width: "60px", height: "60px", bottom: "65px", right: "10px", backgroundColor: "#25d366",color: "floralwhite", borderRadius: "50px",
          textAlign:"center", fontSize:"30px", boxShadow: "1px 1px 2px #888", zIndex:"1000"}}>
          <i style={{ marginTop: 16 }} className="fa fa-whatsapp"></i>
        </a>
      </section>
      <Footer />
    </>
  );
}
