import React from "react";

export default function Footer() {

  function Copi(copyText) {
    navigator.clipboard.writeText("malito:comercial@brst.com.br");
  }

  return (
    <>
      <footer className="relative bg-blueGray-200 pt-8 pb-6">
        <div
          className="bottom-auto top-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden -mt-20 h-20"
          style={{ transform: "translateZ(0)" }}
        >
          <svg
            className="absolute bottom-0 overflow-hidden"
            xmlns="http://www.w3.org/2000/svg"
            preserveAspectRatio="none"
            version="1.1"
            viewBox="0 0 2560 100"
            x="0"
            y="0"
          >
            <polygon
              className="text-blueGray-200 fill-current"
              points="2560 0 2560 100 0 100"
            ></polygon>
          </svg>
        </div>
        <div className="container mx-auto px-4">
          <div className="flex flex-wrap text-center lg:text-left">
            <div className="w-full lg:w-6/12 px-4">
              <h4 className="text-3xl font-semibold  text-blueGray-500">Endereço</h4>
              <h5 className="text-lg mt-0 mb-2 text-blueGray-601">
                Rua das Primaveras, N° 1537 - Jardim Jacarandas
                CEP: 78557-730 - Sinop - MT
              </h5>
              <div className="mt-6 lg:mb-0 mb-6">

                <button  className="bg-white text-lightBlue-400 shadow-lg font-normal h-10 w-10 items-center justify-center align-center rounded-full outline-none focus:outline-none mr-2" type="button" >
                  <a href="https://www.instagram.com/ruralhubsinop/"target="_blank" >
                    <i className="text-pink-400 fab fa-instagram text-lg leading-lg " />
                  </a>
                </button>
                
                {/* <button title="Clique" data-tooltip-target="tooltip-click" data-tooltip-trigger="click" className="bg-white text-lightBlue-600 shadow-lg font-normal h-10 w-10 items-center justify-center align-center rounded-full outline-none focus:outline-none mr-2" type="button">
                  
                  <a onClick={Copi()} data-toggle="tooltip" data-placement="top" title="Clique"> 
                    <i className="text-lightBlue-400 fa fa-envelope text-lg leading-lg " />
                  </a>
                </button> */}

                <button
                  data-tooltip-target="tooltip-click"
                  data-tooltip-trigger="click"
                  type="button"
                  onClick={() => Copi()}
                  className="bg-white text-lightBlue-600 shadow-lg font-normal h-10 w-10 items-center justify-center align-center rounded-full outline-none focus:outline-none mr-2">
                    <a onClick={Copi()} data-toggle="tooltip" data-placement="top" title="Clique"> 
                      <i className="text-lightBlue-400 fa fa-envelope text-lg leading-lg " />
                    </a>
                </button>
                <div id="tooltip-click" role="tooltip" className="inline-block absolute invisible z-10 py-2 px-3 text-sm font-medium text-white bg-gray-900 rounded-lg shadow-sm opacity-0 tooltip dark:bg-gray-700">
                    E-mail copiado
                    <div className="tooltip-arrow" data-popper-arrow></div>
                </div>


                <button className="bg-white text-pink-400 shadow-lg font-normal h-10 w-10 items-center justify-center align-center rounded-full outline-none focus:outline-none mr-2" type="button" >
                  <a href="https://www.facebook.com/BrasilSistemas/?fref=ts" target="_blank" >
                    <i className="text-lightBlue-600 fab fa-facebook text-lg leading-lg " />
                  </a>
                </button>
              </div>
            </div>
            <div className="w-full lg:w-6/12 px-4">
              <div className="flex flex-wrap items-top mb-6">
                <div className="w-full lg:w-6/12 px-4 ml-auto">
                  <h4 className="text-3xl font-semibold  text-blueGray-500">Horários</h4>
                  <h5 className="text-lg mt-0 mb-2 text-blueGray-601">
                    Seg à Sex, 7:30 - 17:30/ Sab, 7:30 - 11:30
                  </h5>
                </div>
              </div>
            </div>
          </div>
          <hr className="my-6 border-blueGray-300" />
          <div className="flex flex-wrap items-center md:justify-between justify-center">
            <div className="w-full md:w-4/12 px-4 mx-auto text-center">
              <div className="text-sm text-blueGray-500 font-semibold py-1">
                © 2022 RuralHub 
                <a
                  href="https://www.creative-tim.com?ref=nr-footer"
                  className="text-blueGray-500 hover:text-blueGray-800"
                >
                </a>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
    
  );
}
